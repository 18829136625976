import {createStore} from 'vuex';
import catalog from './modules/catalog';
import cart from "@/store/modules/cart";
import client from "@/store/modules/client";
import orders from "@/store/modules/orders";

export default createStore({
    modules: {
        catalog,
        client,
        cart,
        orders
    },
    state: {
        token: null,
        isLoading: false,
        api_url: 'https://sgasistemas.sgaweb.com.br/api/catalogs',
        // api_url: 'http://localhost:8080/sgaweb/api/catalogs'

    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setLoading(state, status) {
            state.isLoading = status;
        },
    },
    getters: {
        token: state => state.token,
        isLoading: state => state.isLoading,

    },
});