import store from '../store';
import moment from 'moment';

async function updateTokenIfNeeded(urlToken, storeToken) {
    const storedToken = storeToken || sessionStorage.getItem('token');

    if (urlToken && urlToken !== storedToken) {
        sessionStorage.setItem('token', urlToken);
        sessionStorage.removeItem('cart');
        store.commit('setToken', urlToken);
    } else if (!storeToken) {
        store.commit('setToken', storedToken);
    }
}

async function fetchCatalogIfNeeded(token, urlToken, storedToken) {
    const products = store.getters['catalog/productsData'];

    if (!products || urlToken !== storedToken) {
        try {
            await store.dispatch('catalog/fetchDataCatalog', token);
        } catch (error) {
            console.error('Erro ao buscar dados do catálogo:', error);
            throw error;
        }
    }
    store.dispatch('cart/initializeCart');

}

export default async function authMiddleware(to, from, next) {
    const urlToken = to.query.token;
    const storeToken = store.getters['token'];
    const token = urlToken || storeToken || sessionStorage.getItem('token');
    await updateTokenIfNeeded(urlToken, storeToken);

    if (!token) {
        next({ name: 'not-found' });
        return;
    }

    try {
        await fetchCatalogIfNeeded(token, urlToken, storeToken);
        const catalog = store.getters['catalog/catalogData'];
        const expired = moment().isAfter(catalog.expiration_date, 'day');
        console.log(expired)

        next();
    } catch {
        next({ name: 'not-found' });
    }
}
