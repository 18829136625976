import CatalogService from '@/services/catalogService';

export default {
    namespaced: true,
    state: {
        catalog: null,
        products: null,
        hostcobol: null,
        filtersProduct: {
            marca: [],
            grupo: [],
        },
        sortOrder: 'asc-name'
    },
    mutations: {
        SET_CATALOG_DATA(state, data) {
            state.catalog = data;
        },
        SET_PRODUCT_DATA(state, data) {
            state.products = data;
        },
        SET_HOSTCOBOL_DATA(state, data) {
            state.hostcobol = data;
        },
        SET_CLIENT_DATA(state, data) {
            state.client = data;
        },
        SET_FILTERS_PRODUCTS(state, filters) {
            state.filtersProduct = filters;
        },
        SET_SORT_ORDER(state, sortOrder) {
            state.sortOrder = sortOrder;
        },
        UPDATE_STOCK(state, stockData) {
            const product = state.products.find(p => p.id === stockData.id_product);
            if (product) {
                product.estoque = stockData.estoque;
            }
        },
        UPDATE_PRODUCT(state, payload) {
            const index = state.products.findIndex(p => p.id === payload.id);

            if (index > -1) {
                state.products.splice(index, 1, payload);
            }
        },
    },
    actions: {
        async fetchDataCatalog({commit}, token) {
            try {
                commit('setLoading', true, {root: true});

                const response = await CatalogService.fetchCatalog(token);
                const {items, client_data} = response.data;

                items.forEach(item =>
                    item.imagens.forEach(foto =>
                        foto.file = `${response.data.hostcobol}fotos/produtos/${item.reference.substring(0, 1).toUpperCase()}/${foto.file}`
                    )
                )
                if (client_data?.avatar){
                    const sigla = client_data.nome.substring(0, 2).toUpperCase();
                    client_data.avatar = response.data.hostcobol + 'fotos/clientes/' + sigla + '/' + client_data.avatar;
                }
                commit('SET_PRODUCT_DATA', items);
                commit('client/SET_CLIENT_DATA', client_data,{ root: true });
                commit('SET_HOSTCOBOL_DATA', response.data.hostcobol);
                commit('SET_CATALOG_DATA', response.data);
            } catch (error) {
                console.error(error)
                throw error;
            } finally {
                commit('setLoading', false, {root: true})
            }
        },
    },
    getters: {
        getProductById: (state) => (id) => {
            return state.products.find(product => product.id === id);
        },
        productsData: state => state.products,
        catalogData: state => state.catalog,
        hostCobolData: state => state.hostcobol,
        filtersData: state => state.filtersProduct,
        filteredProducts: (state) => {
            const filterSummary = state.filtersProduct;
            const fieldSummary = ["marca", "grupo", "subgrupo", "linha", "colecao"];

            const hasFilters = fieldSummary.some(field => filterSummary[field] && filterSummary[field].length);
            // const hasSearch = filterSummary.search && filterSummary.search.trim().length > 0;

            function applySort(products, sortOrder) {
                switch (sortOrder) {
                    case 'asc-name':
                        return products.sort((a, b) => a.reference_name.localeCompare(b.reference_name));
                    case 'desc-name':
                        return products.sort((a, b) => b.reference_name.localeCompare(a.reference_name));
                    case 'asc-price':
                        return products.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                    case 'desc-price':
                        return products.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
                    default:
                        return products;
                }
            }

            // if (!hasFilters && !hasSearch) {
            if (!hasFilters) {
                return applySort(state.products, state.sortOrder);
            }

            // const match = (term, string) => string.toLowerCase().includes(term.toLowerCase());

            const filtered = state.products.filter(product => {
                const matchesFieldSummary = fieldSummary.every(field => {
                    const filterValues = filterSummary[field] || [];
                    return filterValues.length === 0 || filterValues.includes(product[field]);
                });

                // const term = filterSummary.search;
                // const {referencia, descricao} = product;
                // const matchesSearch = hasSearch
                //     ? (referencia && match(term, referencia)) ||
                //     (descricao && match(term, descricao)) ||
                //     fieldSummary.some(field => product[`${field}_desc`] && match(term, product[`${field}_desc`])) ||
                //     product.estoque_cores.some(c => match(term, c.cor) || match(term, c.cor_desc))
                //     : true;

                return matchesFieldSummary //&& matchesSearch;
            });

            return applySort(filtered, state.sortOrder);
        },
    },
};

