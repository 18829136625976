import axios from 'axios';
import store from "@/store";

export default {
  async fetchCatalog(token) {
    try {
      const API_URL = store.state.api_url;

      const response = await axios.get(`${API_URL}/token/${token}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar dados do catálogo:', error);
      throw error;
    }
  },

  async getDataItem(id) {
    try {
      const API_URL = store.state.api_url;

      const response = await axios.get(`${API_URL}/items/${id}`);
      return response.data.data
    } catch (error) {
      console.error('Erro ao buscar dados do catálogo:', error);
      throw error;
    }
  },
};
