import orderService from "@/services/orderService";

export default {
    namespaced: true,
    state: {
        order: null,
        deliveryMethod: null,
        deliveryAddress: {},
        paymentMethod: null,
        paymentDetails: {},
    },
    mutations: {
        SET_ORDER_DATA(state, data) {
            state.order = data;
        },
        UPDATE_ORDER_DATA(state, data) {
            state.order = {...state.order, ...data};
        },
        SET_DELIVERY_ADDRESS(state, address) {
            state.deliveryAddress = address;
        },
        SET_DELIVERY_METHOD(state, method) {
            state.deliveryMethod = method;
        },
        SET_PAYMENT_METHOD(state, method) {
            state.paymentMethod = method;
        },
        SET_PAYMENT_DETAILS(state, details) {
            state.paymentDetails = details;
        },
        CLEAR_ORDER_DATA(state) {
            state.order = null;
            state.deliveryMethod = null;
            state.deliveryAddress = {};
            state.paymentMethod = null;
            state.paymentDetails = {};
        },
    },
    actions: {
        async processOrder({state, commit, rootState}) {
            try {
                commit('setLoading', true, {root: true});

                const {cart} = rootState.cart
                const {client} = rootState.client
                const {catalog} = rootState.catalog
                const order = {
                    catalog: catalog.id,
                    buyer: {
                        id: client.codigo,
                        name: client.nome,
                        document: client.cgc_cpf,
                        documentType: "CPF",
                        phone: client.telefone,
                        cellPhone: client.celular,
                        companyStateTaxId: client.documento,
                        dateOfBirth: client.data_nascimento,
                        email: client.email
                    },
                    deliveryMethod: state.deliveryMethod,
                    shipping: state.deliveryAddress,
                    items: cart.map(item => ({id: item.id_product, quantity: item.quantity})),
                    payment: {
                        method: state.paymentMethod,
                        ...state.paymentDetails
                    }
                }
                const response = await orderService.createOrder(order);
                commit('SET_ORDER_DATA', response.data)
            } catch (erro) {
                console.log(erro)
                throw erro;

            } finally {
                commit('setLoading', false, {root: true});
            }
        }
    },
    getters: {
        orderData: state => state.order,
        deliveryAddress: state => state.deliveryAddress,
    }
}