export default {
    install(app) {
        app.config.globalProperties.$formatNumber = (value, type) => {
            const formatters = {
                currency: { style: 'currency', currency: 'BRL' },
                float: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                int: { maximumFractionDigits: 0 }
            };

            return new Intl.NumberFormat('pt-BR', formatters[type] || formatters.float).format(isNaN(value) ? 0 : value);
        };

    }
};
