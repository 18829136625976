<template>
  <div class="daisy-navbar bg-base-100 shadow-md">
    <div class="container mx-auto sm:px-6">
      <div class="daisy-navbar-start flex items-center">
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/images/logo-chapada.png" alt="Logo" class="h-12 w-auto"/>
        </router-link>
      </div>
      <div class="daisy-navbar-center hidden sm:block">
        <h4 class="text-xl font-semibold">SGASoft</h4>
      </div>
      <div class="daisy-navbar-end flex">
        <CartDropdown v-if="!isCheckout"/>
        <ProfileDropdown/>
      </div>
    </div>
  </div>
</template>

<script setup>

import CartDropdown from "@/components/CartNavbar/CartDropdown.vue";
import {useRoute} from 'vue-router';
import {computed} from "vue";
import ProfileDropdown from "@/components/ProfileDropdown.vue";

const route = useRoute();
const isCheckout = computed(() => route.path.startsWith('/checkout'));

</script>

<style scoped>
</style>