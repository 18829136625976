<template>
    <footer class="bg-gray-800 text-white p-4 mt-4">
        <div class="container mx-auto text-center">
            &copy; 2024 Minha Loja. Todos os direitos reservados.
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
};
</script>